.map-list-card {
  cursor: pointer;
  margin: 0;
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid var(--app-border-color);
  box-sizing: border-box;
  border-radius: 15px;
  padding: 11px;

  .image-view {
    height: 100%;
    border-radius: 10px;
  }

  .text-label {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    color: var(--app-label-color);
    height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .list-info-view {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    align-items: center;
    color: var(--app-label-color);
    padding-right: 15px;
    padding-left: 15px;
    width: 50%;
    place-items: flex-start;

    .map-icon-label {
      width: 16px;
      font-size: 16px;
      color: var(--color-pink-500);
      margin-right: 6px;
    }

    .map-value-label {
      flex: 1;
    }
    .map-title-label::after {
      content: ':';
      margin-right: 5px;
    }
  }
}

.top-map-info-view {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .icon-view {
    flex: 0 1 auto;
    display: flex;
    position: relative;

    app-image-viewer {
      width: 45px;
      height: 45px;
      min-width: 45px;
      min-height: 45px;
      margin-right: 10px;

      .image-container-view {
        padding: 2px !important;
      }
    }

    .premium-badge {
      position: absolute;
      z-index: 9999999;
      bottom: 1px;
      right: 5px;
      font-size: 16px;
    }
  }

  .title-view {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-self: center;
    width: 30px;
    .title-value-label {
      display: block;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      color: var(--app-title-label-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .premium-badge-label {
      margin-top: 3px;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 0.02em;

      color: var(--color-pink-500);
    }
  }

  .type-view {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
    .type-value-label {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      color: var(--app-title-label-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }
    .type-icon {
      margin-left: 6px;
      font-size: 22px;
    }
  }
}

.map-description-label {
  max-width: 100%;
  max-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.map-info-view {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  align-items: center;
  color: var(--app-label-color);

  .map-value-label {
    flex: 1;
  }

  .map-icon-label {
    width: 16px;
    font-size: 16px;
    color: var(--color-pink-500);
    margin-right: 6px;
  }
  .map-title-label::after {
    content: ':';
    margin-right: 5px;
  }
}

.map-info-window {
  min-width: 200px;
  cursor: pointer;

  @media (max-width: 500px) {
    min-width: 150px;
  }
}
