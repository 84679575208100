@tailwind base;
@tailwind components;
@tailwind utilities;

/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '~@ng-select/ng-select/themes/default.theme.css';
@import 'theme/color';
@import 'theme/variable';
@import 'theme/map-list';

@import '~swiper/swiper.min.css';
@import '~swiper/modules/pagination/pagination.min.css';
@import '~@ionic/angular/css/ionic-swiper';
html,
body {
  color: $color-body;
  --ion-font-family: 'Comfortaa', sans-serif;
}

html, input, textarea {
  caret-color: $color-primary; // since Ionic 5 the caret was no longer visible
}

.gm-style-iw-d {
  max-height: none !important;
}

.gm-style-iw {
  max-height: none !important;
}

.gm-ui-hover-effect:focus {
  outline: none;
}

@font-face {
  font-family: 'Comfortaa';
  font-display: swap;
  src: url('/assets/fonts/Comfortaa/Comfortaa-VariableFont_wght.woff2');
}

* {
  font-family: 'Comfortaa', sans-serif;

  text-size-adjust: none;
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
}

.swiper-slide {
  height: auto !important;
}
table.stmz {
  tr td {
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0.02em;
  }
  tr td:first-child {
    width: 100px;
    color: var(--app-placeholder-color);
  }
  tr td:nth-child(2) {
    padding-left: 5px;
    color: var(--app-title-label-color);
  }
}
.phone-floating-label ion-label {
  -webkit-transform: translateY(50%) scale(0.75) !important;
  transform: translateY(50%) scale(0.75) !important;
}
.ant-select-arrow i {
  display: none;
}
.ant-select-arrow:after {
  content: '';
  display: inline-block;
  height: 0;
  pointer-events: none;
  position: relative;
  width: 0;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
}
.main-container {
  padding: 15px;
  margin-top: calc(var(--ion-safe-area-top) - 80px);
}

.alert-button.app-alert-button {
  color: var(--app-title-label-color) !important;
}

.container {
  padding: 0;
}

.info-label {
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: var(--app-title-label-color);
  margin-top: 5px;
  margin-right: 5px;
}

.page-section {
  margin-top: 15px;
}

.page-section:last-child {
  margin-bottom: 30px;
}

.backdrop-view {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.section-divider-view {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 15px 0;

  .section-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: var(--app-title-label-color);
  }

  .divider-view {
    flex: 1;
    height: 1px;
    margin: 3px;
    border: 1px dashed var(--app-popup-background);
  }
}

.error-view {
  border: 1px solid var(--color-pink-500);
  border-radius: 6px;
  padding: 10px;
  margin-bottom: 10px;
  color: var(--color-pink-500);
}

.validation-container {
  .validation-error-label {
    color: red;
    font-size: 12px;
    margin-top: 10px;
  }
}
@keyframes fadeInFadeOutSelection {
  0% {
    border: 1px solid rgba(239, 85, 111, 1);
    transform: scale(1);
  }
  50% {
    transform: scale(1.15);
  }
  99% {
    border: 1px solid rgba(239, 85, 111, 0);
    transform: scale(1);
  }
  100% {
    border: 1px solid var(--app-border-color);
  }
}

.map-card-selected {
  border: 1px solid rgba(239, 85, 111, 0);
  -webkit-animation: fadeInFadeOutSelection 1s;
  animation: fadeInFadeOutSelection 1s;
  animation-fill-mode: forwards;
}

.border-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  .divider {
    flex: 1;
    border: 1px dashed var(--app-popup-background);
    height: 1px;
    margin-right: 5px;
  }
  .remove-icon {
    color: var(--app-blue-color);
  }
}
ion-toggle {
  --background: var(--app-popup-background);
  --background-checked: #0f67ae;
  --handle-background: white;
  --handle-background-checked: white;
  --border-radius: 25px;
  --handle-width: 16px;
  --handle-height: 16px;
  height: 20px;
  --handle-spacing: 2px;
  width: 40px;
}

.rosypet-table {
  width: 100%;
  table-layout: fixed;

  thead tr {
    background: var(--app-popup-background);
    height: 45px;
    padding: 10px;

    th {
      font-style: normal;
      font-weight: bold;
      font-size: 11px;
      line-height: 12px;
      text-align: left;
      color: white;
      padding: 10px 15px;
    }
  }

  tbody {
    border: 1px solid var(--app-border-color);

    tr {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: var(--app-title-label-color);
      text-align: left;

      td {
        padding: 10px 15px;
      }

      .action-buttons {
        margin-left: 10px;

        ion-icon {
          width: 12px;
        }

        span {
          margin-left: 3px;
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 11px;
          text-align: right;
          text-decoration-line: underline;
        }
      }
    }

    tr.disabled {
      background: #dfe8f1;
    }
  }
}

.section-header {
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  .divider {
    flex: 1;
    border: 1px dashed var(--app-popup-background);
    height: 1px;
    margin: 7px;
  }
}

html {
  --header-height: 160px; // default value for ssr
}
ion-content {
  align-self: center;
  --padding-top: var(--header-height) !important;
  --padding-bottom: 18px;
  --padding-start: 32px;
  --padding-end: 32px;
}
@media (max-width: 799px) {
  ion-content {
    --padding-start: 16px;
    --padding-end: 16px;
  }
}

.section-container {
  margin-top: 15px;
}

.page-title-label {
  margin: 15px;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  color: var(--app-title-label-color);
}

.page-subtitle-label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: var(--app-label-color);
}

.card-bottom-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 12px 13px;
  background: var(--app-border-color);
  flex-wrap: wrap;
}

ion-toggle {
  --background-checked: var(--app-title-label-color-no-darkmode);
}

.header-badge-view {
  position: absolute;
  left: 0;
  top: 0;
  width: 17px;
  height: 17px;
  background: var(--color-pink-500);
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  place-content: center;
}

.menu-button {
  img,
  ion-icon {
    width: 26px;
    height: 26px;
    font-size: 20px;
  }

  --color: var(--app-icon-color);
}

ion-row {
  margin: 0 calc(-1 * var(--ion-row-padding));
  --ion-grid-column-padding: var(--ion-row-padding);
}

app-button {
  ion-chip {
    color: var(--app-blue-color);
    background-color: var(--ion-color-primary-contrast);
    font-size: 80%;
    height: 60%;
  }
}

.hasError .text-danger {
  font-size: 12px;
  margin-top: 5px;
}

ion-item,
ion-list {
  background: none;
  --ion-item-background: none;
}

.item-divider {
  border: 1px dashed var(--app-divider-color);
  margin: 28px 20px;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p,
label {
  @apply tw-text-primary-1
}

p {
  @apply tw-mt-2 tw-mb-2 tw-text-sm;
}

h1 {
  @apply tw-mt-8 tw-mb-8 tw-text-xl md:tw-text-3xl tw-font-bold;
}

h2 {
  @apply tw-mt-4 tw-mb-4 tw-text-lg md:tw-text-2xl tw-font-bold;
}

h3 {
  @apply tw-mt-2 tw-mb-2 tw-text-md md:tw-text-lg tw-font-bold;
}

a {
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

ul {
  @apply tw-text-sm tw-list-disc tw-pl-5;
}
li {
  @apply tw-mb-1;
}
ol {
  @apply tw-text-sm tw-list-decimal tw-pl-5;
}

/**
 Modal Related styles
 */
.modal-header .header-title {
  margin: auto;
  font-size: 24px;
  font-weight: bold;
}

.modal-container {
  padding: 20px;
}

.form-group {
  &.has-error {
    .form-control {
      border: 1px solid $color-red;
    }
  }
}

.remove-button {
  background-color: var(--color-pink-500) !important;
  color: white !important;
}

.text-error {
  color: $color-red;
}

.popover {
  max-width: 400px;
}

.toast {
  position: fixed;
  right: 1.5em;
  margin: 0.5em;
  z-index: 1200;
  bottom: 0;
}

button {
  ion-icon {
    font-size: 1.4em; // TODO: this is temporary to make sure ion-icon are displayed as they were before, should be removed
  }
}

section {
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (min-width: $md) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

table.dataTable thead th,
table.dataTable thead td {
  padding-left: 8px;
}

@media (prefers-color-scheme: dark) {
  //table.dataTable tr, table.dataTable td {
  //    background: #222;
  //}
}

#back-button {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 11px;
  text-decoration-line: underline;
  color: var(--color-pink-500);
  --color: var(--color-pink-500);

  img {
    margin-right: 5px;
    width: 10px;
  }
}

input {
  background-color: white;
}
.fileInput {
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 100%;
  z-index: 99;
  opacity: 0;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

.fileInput[disabled] + .img-space {
  pointer-events: none;
  cursor: not-allowed !important;
}

.table-wrap {
  position: relative;
  max-width: 100%;
  overflow-x: auto;

  .no-data-available {
    text-align: center;
  }

  .table-loading {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    & > div {
      padding-top: 20px;
      text-align: center;
      font-size: 1.2em;
      color: #333;
      background-color: #fff;
      width: 100%;
      height: 40px;
      background: linear-gradient(
        90deg,
        hsla(0, 0%, 100%, 0) 0,
        hsla(0, 0%, 100%, 0.9) 25%,
        hsla(0, 0%, 100%, 0.9) 75%,
        hsla(0, 0%, 100%, 0)
      );
    }
  }

  th[appSortable] {
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
  }

  th[appSortable].desc:after,
  th[appSortable].asc:after {
    content: '';
    display: block;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAmxJREFUeAHtmksrRVEUx72fH8CIGQNJkpGUUmakDEiZSJRIZsRQmCkTJRmZmJgQE0kpX0D5DJKJgff7v+ru2u3O3vvc67TOvsdatdrnnP1Y///v7HvvubdbUiIhBISAEBACQkAICAEhIAQ4CXSh2DnyDfmCPEG2Iv9F9MPlM/LHyAecdyMzHYNwR3fdNK/OH9HXl1UCozD24TCvILxizEDWIEzA0FcM8woCgRrJCoS5PIwrANQSMAJX1LEI9bqpQo4JYNFFKRSvIgsxHDVnqZgIkPnNBM0rIGtYk9YOOsqgbgepRCfdbmFtqhFkVEDVPjJp0+Z6e6hRHhqBKgg6ZDCvYBygVmUoEGoh5JTRvIJwhJo1aUOoh4CLPMyvxxi7EWOMgnCGsXXI1GIXlZUYX7ucU+kbR8NW8lh3O7cue0Pk32MKndfUxQFAwxdirk3fHappAnc0oqDPzDfGTBrCfHP04dM4oTV8cxr0SVzH9FF07xD3ib6xCDE+M+aUcVygtWzzbtGX2rPBrEUYfecfQkaFzYi6HjVnGBdtL7epqAlc1+jRdAap74RrnPc4BCijttY2tRcdN0g17w7HqZrXhdJTYAuS3hd8z+vKgK3V1zWPae0mZDMykadBn1hTQBLnZNwVrJpSe/NwEeDsEwCctEOsJTsgxLvCqUl2ACftEGvJDgjxrnBqkh3ASTvEWrIDQrwrnJpkB3DSDrGW7IAQ7wqnJtkBnLRztejXXVu4+mxz/nQ9jR1w5VB86ejLTFcnnDwhzV+F6T+CHZlx6THSjn76eyyBIOPHyDakhBAQAkJACAgBISAEhIAQYCLwC8JxpAmsEGt6AAAAAElFTkSuQmCC')
      no-repeat;
    background-size: 22px;
    width: 22px;
    height: 22px;
    float: left;
  }

  th[appSortable].desc:after {
    transform: rotate(180deg);
    -ms-transform: rotate(180deg);
  }
}

.alert-wrapper {
  .alert-button.sc-ion-alert-ios:last-child {
    font-weight: normal;
  }
}

.visible-mobile {
  display: none;
}

@media (max-width: 799px) {
  .hidden-mobile {
    display: none !important;
    --display: none !important;
  }

  .visible-mobile {
    display: block !important;
  }
}
